<template>
  <div class="news-page">
    <template v-if="newItem">
      <div class="block-banner news-banner--detail" :style="`background-image: url(/dailypacific/storage/app/public/${newItem.urlbanner})`">
        <div class="bg--black">
          <div class="container">
            <h2 class="block-banner__title"  v-scroll-reveal="{
              distance: '80px',
              origin: 'left'
            }">{{newItem.titulo}}</h2>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="news">
          <div class="news__container">
            <div class="news__detail row">
              <div
                class="col-xs-12 col-md-10 col-md-offset-1"
                v-html="newItem.noticia"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "NewsPage",
  props: {
    news: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: null
    }
  },
  computed: {
    newItem() {
      return this.news.find(e => parseInt(e.id) === parseInt(this.id));
    }
  },
}
</script>